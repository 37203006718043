<template>
  <select
    :name="name"
    :id="`form_field_${name}`"
    :required="required"
    :class="`form_input ${extraClass}`" >
    <form-option value="" :is-default="false" :label="baseLabel" />
    <form-option
      v-for="(option, optionIndex) in options"
      :key="'option-' + optionIndex"
      :value="option.value"
      :label="option.label"
      :selected="option.default" />
  </select>
</template>

<script>
import FormOption from './option'
export default {
  name: 'FormSelect',
  components: { FormOption },
  props: {
    required: {
      default: false,
      required: false,
      type: Boolean
    },
    baseLabel: {
      default: 'Selecteer optie',
      required: false,
      type: String
    },
    name: {
      default: '',
      required: true,
      type: String
    },
    id: {
      default: '',
      required: true,
      type: String
    },
    options: {
      default: () => [],
      required: true,
      type: Array
    },
    extraClass: {
      default: '',
      required: false,
      type: String
    }
  }
}
</script>
