<template>
  <div
    class="product__item border border-solid border-gray-medium rounded mb-8 flex flex-wrap py-4"
  >
    <div class="w-full tablet:w-1/5 px-4 flex items-center justify-center">
      <div v-if="data.images && data.images.length > 0" class="product__image">
        <nuxt-link :to="localePath(link)">
          <img :src="`${data.images[0]}`" :alt="`${data.sku} - ${data.name}`" />
        </nuxt-link>
      </div>
      <div v-else class="product__image">
        <nuxt-link :to="localePath(link)">
          <img
            :alt="`${data.sku} - ${data.name}`"
            src="/images/placeholder.jpg"
          />
        </nuxt-link>
      </div>
    </div>
    <div class="w-full tablet:w-1/2 px-4">
      <div class="product__info p-4">
        <header-h5 :content="`${data.name}`" mt="0" mb="0" />
        <header-h6
          v-if="data.differentation"
          :content="data.differentation.replace(/(?:\r\n|\r|\n)/g, '<br>')"
          mt="0"
          mb="0"
          weight="light"
          extra-class="italic"
          style="font-size:1rem"
        />
        <p>
          <strong v-t="'components.products.sku'" /><br>
          <strong
            v-if="data.to_be_anounced === 0"
            v-html="data.sku"
            class="text-red"
          />
          <strong v-else v-t="'components.products.tba'" class="text-red" />
        </p>
        <p v-if="(data.oems && Object.keys(data.oems).length > 0) && !loggedIn">
          <span v-html="$t('components.products.more_oem')"></span>
        </p>
        <p v-if="(data.oems && Object.keys(data.oems).length > 0) && loggedIn">
            <strong> OEM: </strong><br>
            {{ Object.values(data.oems).slice(0, 2).join(', ') }}
            <template v-if="Object.values(data.oems).length > 2">
                ...
            </template>
        </p>
      </div>
    </div>
    <div class="w-full tablet:w-3/10 px-4">
      <div v-if="data.to_be_anounced === 0" class="px-4 tablet:px-8 pb-4">
        <div>
          <strong v-t="'components.products.stock_status'" /><br>
          <div
            :class="
              `bg-${
                parseInt(stock) === 0 ? 'red' : 'green'
              } text-white py-1 px-2 rounded text-sm`
            "
          >
            <div v-if="parseInt(stock) === 0">
              <span v-t="'components.products.nostock'" />
            </div>
            <div v-else>
              <span v-t="'components.products.in_stock'" />:
              {{ stock > 10 ? '10+' : stock }}
            </div>
          </div>
        </div>
        <div>
          <strong v-t="'components.products.stock_status_fr'" /><br>
          <div
              :class="
          `bg-${
            parseInt(stockFR) === 0 ? 'red' : 'green'
          } text-white py-1 px-2 rounded text-sm`
        "
          >
              <div v-if="parseInt(stockFR) === 0">
                  <span v-t="'components.products.nostock'" />
              </div>
              <div v-else>
                  <span v-t="'components.products.in_stock'" />:
                  {{ stockFR > 10 ? '10+' : stockFR }}
              </div>
          </div>
        </div>
        <div>
          <strong v-t="'components.products.stock_status_ie'" /><br>
          <div
            :class="`bg-${parseInt(stockIE) === 0 ? 'red' : 'green'} text-white py-1 px-2 rounded text-sm`">
              <div v-if="parseInt(stockIE) === 0">
                  <span v-t="'components.products.nostock'" />
              </div>
              <div v-else>
                  <span v-t="'components.products.in_stock'" />:
                  {{ stockIE > 10 ? '10+' : stockIE }}
              </div>
          </div>
        </div>
        <div class="flex">
          <p>
            <strong v-t="'components.products.price'" /><br>
            <span>
              {{
                price > 0
                  ? new Intl.NumberFormat('en-GB', {
                      style: 'currency',
                      currency: currency
                    }).format(price)
                  : $t('components.products.request')
              }}
            </span>
          </p>
          <button
            v-if="$auth.loggedIn"
            @click="addToFavorites"
            class="text-lg block my-auto ml-auto button-icon">
            <font-awesome-icon
              v-if="isInFavorites"
              :icon="['fas', 'heart']"/>
            <font-awesome-icon
              v-else
              :icon="['far', 'heart']"/>
          </button>
        </div>
      </div>
      <div v-else class="px-4 tablet:px-8 pb-4">
        <strong v-t="'components.products.tba_message'" /><br>
      </div>
      <div class="px-4 w-full">
        <button
          v-if="data.to_be_anounced === 0"
          @click="inCart"
          class="button button--secondary--dark block text-sm"
        >
          <span v-html="$t('components.products.quick_cart')" />
        </button>
        <br>
        <button-primary
          :text="$t('components.products.read_more')"
          :path="link"
          extra-class="block text-sm"
        />
      </div>
    </div>

    <client-only>
      <modal :height="'auto'" :scrollable="true" name="quickEnquiry">
        <div class="v--modal-top-right text-3xl pr-4">
          <button @click="$modal.hide('quickEnquiry')">
            &times;
          </button>
        </div>
        <div class="v--modal-content px-6 py-4">
          <header-h3 :content="$t('components.products.quick_cart')" mt="0" />
          <p v-t="'components.products.quick_cart_description'" />
          <form @submit.prevent="quickEnquiry">
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.company_name')}`"
                for-id="form_field_company_name"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="company_name"
                :value="company_name"
                :required="true"
                :placeholder="`${$t('components.account.form.company_name')} *`"
                name="company_name"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.first_name')} *`"
                for-id="form_field_first_name"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="first_name"
                :value="first_name"
                :required="true"
                :placeholder="`${$t('components.account.form.first_name')} *`"
                name="first_name"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.last_name')} *`"
                for-id="form_field_last_name"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="last_name"
                :value="last_name"
                :required="true"
                :placeholder="`${$t('components.account.form.last_name')} *`"
                name="last_name"
              />
            </div>
            <div v-if="$i18n.locale === 'en'" class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.house_number')} *`"
                for-id="form_field_house_number"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="house_number"
                :value="house_number"
                :required="true"
                :placeholder="`${$t('components.account.form.house_number')} *`"
                name="house_number"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.street')} *`"
                for-id="form_field_street"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="street"
                :value="street"
                :required="true"
                :placeholder="`${$t('components.account.form.street')} *`"
                name="street"
              />
            </div>
            <div v-if="$i18n.locale !== 'en'" class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.house_number')} *`"
                for-id="form_field_house_number"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="house_number"
                :value="house_number"
                :required="true"
                :placeholder="`${$t('components.account.form.house_number')} *`"
                name="house_number"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.postal_code')} *`"
                for-id="form_field_postal_code"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="postal_code"
                :value="postal_code"
                :required="true"
                :placeholder="`${$t('components.account.form.postal_code')} *`"
                name="postal_code"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.city')} *`"
                for-id="form_field_city"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="city"
                :value="city"
                :required="true"
                :placeholder="`${$t('components.account.form.city')} *`"
                name="city"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.country')} *`"
                for-id="form_field_country"
                extra-class="block mb-2 hidden"
              />
              <div class="form_item">
                <form-input
                  v-model="country"
                  :value="country"
                  :required="true"
                  :placeholder="`${$t('components.account.form.country')} *`"
                  name="country"
                />
                <!--                        <select-->
                <!--                          id="form_field_country"-->
                <!--                          v-model="country"-->
                <!--                          @change="recalculate"-->
                <!--                          class="form_input"-->
                <!--                          required-->
                <!--                        >-->
                <!--                          <option-->
                <!--                            v-html="-->
                <!--                              `${$t('components.account.form.country')} *`-->
                <!--                            "-->
                <!--                            value=""-->
                <!--                          />-->
                <!--                          <option-->
                <!--                            v-for="country in countries"-->
                <!--                            :value="country.id"-->
                <!--                            v-html="country.name"-->
                <!--                          />-->
                <!--                        </select>-->
              </div>
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.phone')}`"
                for-id="form_field_phone"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="phone"
                :value="phone"
                :required="false"
                :placeholder="`${$t('components.account.form.phone')}`"
                type="tel"
                name="phone"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.email')} *`"
                for-id="form_field_email"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="email"
                :value="email"
                :required="true"
                :placeholder="`${$t('components.account.form.email')} *`"
                type="email"
                name="email"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.order_ref')}`"
                for-id="form_field_order_ref"
                extra-class="block mb-2 hidden"
              />
              <form-input
                v-model="order_ref"
                :required="false"
                :placeholder="`${$t('components.account.form.order_ref')}`"
                name="order_ref"
              />
            </div>
            <div class="form-group w-full mb-4">
              <form-label
                :label="`${$t('components.account.form.remarks')}`"
                for-id="form_field_remarks"
                extra-class="block mb-2 hidden"
              />
              <form-textarea
                v-model="remarks"
                :required="false"
                :placeholder="`${$t('components.account.form.remarks')}`"
                name="remarks"
              />
            </div>

            <div>
              <p>
                {{ succesMessage }}
              </p>
            </div>

            <div class="mt-4 pr-4 text-right">
              <button-primary :text="$t('form.send')" type="submit-form" />
            </div>
          </form>
        </div>
      </modal>
      <modal
        v-for="(video, index) in data.videos"
        :key="index"
        :height="'auto'"
        :scrollable="true"
        :name="`video_${index}`"
      >
        <div class="v--modal-top-right text-3xl pr-4">
          <button @click="$modal.hide(`video_${index}`)">
            &times;
          </button>
        </div>
        <div class="v--modal-content px-6 py-4">
          <iframe
            :src="`//www.youtube.com/embed/${getYoutubeId(video.url)}`"
            width="560"
            height="315"
            frameborder="0"
            allowfullscreen
          />
        </div>
      </modal>
    </client-only>
  </div>
</template>

<script>
import portal from '../../service/portal';
import CommonUtils from '/helpers/CommonUtils';

export default {
  name: 'ProductItem',
  props: {
    data: {
      default: () => {},
      required: true,
      type: Object
    },
    link: {
      default: () => {},
      required: true,
      type: Object
    }
  },
  computed: {
    isInFavorites () {
      let favoritesInStore = this.$store.getters.getFavoriteItems;
      return favoritesInStore.indexOf(this.data.id) > -1;
    }
  },
  data() {
    let price = 0;
    let stock = 0;
    let stockFR = 0;
    let stockIE = 0;
    let currency = 'EUR'
    if (this.data.price_object === null) {
      price = 0
      stock = 0
    } else {
      const priceObject = JSON.parse(this.data.price_object.price_object)
      const priceList = this.$store.state.priceList
      price = priceObject['Pricelist' + priceList]
      stock = priceObject.stocklevel
      stockFR = priceObject.stocklevelfr;
      stockIE = priceObject.stocklevelirl;
      currency = CommonUtils.getCurrencyForPriceList(priceList);
    }
    const stockStatus = parseInt(stock) === 0 ? 'low' : parseInt(stock) > 5 ? 'high' : 'normal'
    const stockStatusFR = parseInt(stockFR) === 0 ? 'low' : parseInt(stockFR) > 5 ? 'high' : 'normal';
    const stockStatusIE = parseInt(stockIE) === 0 ? 'low' : parseInt(stockIE) > 5 ? 'high' : 'normal';
    price = price ?? 0;

    return {
      loggedIn: this.$auth.loggedIn,
      price,
      stock,
      stockStatus,
      stockFR,
      stockStatusFR,
      stockIE,
      stockStatusIE,
      currency,
      succesMessage: '',
      company_name: this.$auth.loggedIn ? this.$auth.user.company : '',
      first_name: this.$auth.loggedIn ? this.$auth.user.firstname : '',
      last_name: this.$auth.loggedIn ? this.$auth.user.lastname : '',
      street: this.$auth.loggedIn ? this.$auth.user.street : '',
      house_number: this.$auth.loggedIn ? this.$auth.user.number : '',
      postal_code: this.$auth.loggedIn ? this.$auth.user.postal_code : '',
      city: this.$auth.loggedIn ? this.$auth.user.city : '',
      country: this.$auth.loggedIn ? this.$auth.user.country : '',
      phone: this.$auth.loggedIn ? this.$auth.user.phonenumber : '',
      email: this.$auth.loggedIn ? this.$auth.user.email : '',
      remarks: '',
      order_ref: ''
    }
  },
  methods: {
    inCart() {
      console.log(this.data);
      this.$store.commit('SET_CART_ITEM', {
        product: {
          product: this.data,
          sku: this.data.sku,
          images: this.data.images,
          id: this.data.id,
        },
        price: this.price,
        stock: this.stock,
        currency: this.currency
      })
      if (this.$store.getters.getCartChanged) {
        this.$toast.success(this.$t('components.products.cart_added'))
      } else {
        this.$toast.error(this.$t('components.products.cart_error'))
      }
    },
    quickEnquiry() {
      const body = {}
      if (this.$auth.loggedIn) {
        body.user_id = this.$auth.user.user_id
      } else {
        body.company = this.company_name
        body.firstname = this.first_name
        body.lastname = this.last_name
        body.street = this.street
        body.number = this.house_number
        body.postal_code = this.postal_code
        body.city = this.city
        body.country = this.country
        body.phonenumber = this.phone
        body.email = this.email
      }
      body.reference = this.order_ref
      body.note = this.remarks
      body.order_type = 'enquiry'
      body.order_rows = []
      const row = {}
      row.product_id = this.data.id
      row.quantity = 1
      row.price_per_piece = this.price
      row.currency = this.currency
      body.order_rows.push(row)

      portal
        .post('add-order', body, {
          'Content-Type': 'application/json'
        })
        .then((data) => {
          this.$toast.success(this.$t('components.products.cart_send'))
          dataLayer.push({'event': 'quick_enquiry_send_overview'})

          this.company_name = this.$auth.loggedIn ? this.$auth.user.company : ''
          this.first_name = this.$auth.loggedIn ? this.$auth.user.firstname : ''
          this.last_name = this.$auth.loggedIn ? this.$auth.user.lastname : ''
          this.street = this.$auth.loggedIn ? this.$auth.user.street : ''
          this.house_number = this.$auth.loggedIn ? this.$auth.user.number : ''
          this.postal_code = this.$auth.loggedIn
            ? this.$auth.user.postal_code
            : ''
          this.city = this.$auth.loggedIn ? this.$auth.user.city : ''
          this.country = this.$auth.loggedIn ? this.$auth.user.country : ''
          this.phone = this.$auth.loggedIn ? this.$auth.user.phonenumber : ''
          this.email = this.$auth.loggedIn ? this.$auth.user.email : ''
          this.remarks = ''
          this.order_ref = ''

          this.succesMessage = this.$t('components.products.cart_send')

          setTimeout(() => {
            this.$modal.hide('quickEnquiry')
          }, 2000)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    addToFavorites () {
      if (!this.loggedIn) {
        return;
      }

      let requestParams = {
        user_id: this.$auth.user.user_id,
        product_id: this.data.id
      };

      let headers = {
        'Content-Type': 'application/json'
      }

      if (this.isInFavorites) {
        portal.post(`favourites/remove`, requestParams, headers)
        .then((response) => {
          this.$store.commit('REMOVE_FAVORITE_ITEM', this.data.id);
        });
        return;
      }

      portal.post(`favourites/add`, requestParams, headers)
      .then((response) => {
        this.$store.commit('ADD_FAVORITE_ITEM', this.data.id);
      });
    }
  }
}
</script>
